body{
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	color: $grey;
	line-height: 1.4;

	&.menu-active{
		position: fixed;
		width: 100%;
	}
}

main{
	overflow: hidden;
}

.container{
	max-width: 1250px;
	margin: 0 auto;
	position: relative;

	@media screen and (max-width: 1025px) {
		max-width: 900px;
	}
	@media screen and (max-width: 790px) {
		max-width: 700px;
	}
	@media screen and (max-width: 560px) {
		max-width: 90vw;
	}
}

header{
	padding-top: 45px;
	width: 100%;
	box-shadow: $box-shadow;

	@media screen and (max-width: 1025px) {
		padding: 25px 0;
		padding: 25px 0 5px 0;
	}
	@media screen and (max-width: 560px) {
		//padding: 25px 0 0 0	;
	}
	
	

	.container{
		position: static;
		@media screen and (max-width: 1025px) {
			position: relative;
		}
	}
	.logo img{
		max-width: 130px;
		float: left;
		@media screen and (max-width: 1025px) {
			max-width: 100px;
		}
	}
	nav{
			display: inline-block;
		    /* -webkit-box-pack: end; */
			-ms-flex-pack: end;
			justify-content: flex-end;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			width: 100%;
			max-width: calc(80% - 0px);
			margin-bottom: 0;
			padding: 0;
			padding-left: 25px;
			@media screen and (max-width: 1025px) {
				max-width: 100%;
			}
			@media screen and (max-width: 560px) {
				max-width: 80%;
			}

		.burger-menu{
			display: none;
			@media screen and (max-width: 1025px) {
				display: block;
				height: 3px;
				width: 30px;
				background-color: $grey;
				position: absolute;
				right: 0%;
				top: 30%;
				transform: translateY(-50%);
				transition: .2s ease-in;

				&:before{
					display: block;
					height: 3px;
					width: 35px;
					background-color: $grey;
					position: absolute;
					top: -10px;
					right: 0;
					content: "";
					transition: .2s ease-in;
				}
				&:after{
					display: block;
					height: 3px;
					width: 35px;
					background-color: $grey;
					position: absolute;
					top: 10px;
					right: 0;
					content: "";
					transition: .2s ease-in;
				}

			}

			&.burger-active{
				background-color: inherit;
				
				&:before{
					top: 0px;
					transform: rotate(45deg);
				}
				&:after{
				    top: 0px;
    				transform: rotate(-45deg);
				}
			}
		}
	
		
		ul.menu{
			display: block;
			margin: 0 auto;
			width: 50%;
			
			@media screen and (max-width: 1025px) {
				display: none;
				position: absolute;		
				width: 100%;
				left: 0;
				z-index: 20;
				background-color: #fff;
				//box-shadow: $box-shadow;
				//padding-bottom: 20px;
				&:before{
					width: 100vw;
					position: absolute;
					left: -4vw;
					top: 0;
					content: "";
					background-color: #fff;
					height: 100%;
					z-index: -1;
				}

				&:after{
					width: 110vw;
					position: absolute;
				    left: -10vw;
					bottom: -100%;
					content: "";
					background-color: rgba(0, 0, 0, 0.386);
					height: 100%;
					z-index: -1;	

					width: 120vw;
					left: -10vw;
					bottom: -420%;
					background-color: rgba(0,0,0,.386);
					height: 100vh;
				}
				
			}

			@media screen and (max-width: 780px) {
				&:after{		
				    left: -vw;
				}
			}

			@media screen and (max-width: 780px) {
				padding: 0;

				&:after{
					left: -4.4vw;
					height: 100vh;
					top: 100%;
					width: 100vw;

					
				}
			}
			@media screen and (max-width: 580px) {
				&:after{
					left: -5vw;
				}
			}
			li{
				display: inline-block;
				margin: 0 30px;
				color: #49555D;
				font-size: 1.2em;
				padding-bottom: 45px;
				@media screen and (max-width: 1025px) {
					width: 100%;
					text-align: center;
					margin: 0;
					margin-top: 20px;
					padding-bottom: 15px;
					
				}
			}
		}
	}
	

	ul.lang{
		padding: 0;
		display: inline-block;
		position: absolute;
		margin: 0;
		@media screen and (max-width: 1025px) {
			right: 15%;
			top: 10%;
		}
		
		li{
			display: inline-block;

			&:nth-child(1){
				a:after{
					content: "|";
					position: absolute;
					top: 0;
					right: -5px;
					height: 100%;
				}
			}
			a{
				text-transform: uppercase;
				color: $red;
				font-weight: 300;
				text-decoration: none;
				padding: 0 5px;
				position: relative;
				@media screen and (max-width: 1025px) {
					//display: none;
					
				}
				&.lang_active{
					font-weight: 600;
					@media screen and (max-width: 1025px) {
						display: block;
					
						&:after{
							display: none;
						}
					}
				}
			}
		}
	}
}

.cta-red{
	background-color: $red;
	padding: 14px 48px;
	color: #FFF;
	font-weight: 600;
	border-radius: 60px;
	box-shadow: 0 8px 24px rgba(255, 0, 3, 0.2);
	text-decoration: none;
	margin-top: 2em;
	display: inline-block;
	transform: translateY(0px);
	transition: .2s ease-in-out;

    margin-bottom: 50px;

	
	&:hover{
		transform: translateY(-3px);
		box-shadow: 0 18px 34px rgba(255,0,3,.3);
	}
}
.cta-white-product{
	background-color: #FFF;
	padding: 14px 48px;
	color: $grey;
	font-weight: 600;
	border-radius: 60px;
	box-shadow: $box-shadow;
	text-decoration: none;
	margin: 0 auto;
	margin-bottom: 2em;
	display: inline-block;
	@media screen and (max-width: 1025px) {
		padding: 14px 28px;
	}
}
.cta_white{
	background-color: #fff;
	color: $grey;
	padding: 20px 40px 20px 60px;
	position: relative;
	display: inline-block;
	text-decoration: none;
	border-radius: 60px;
	&:before{
		background: url(/dist/img/cta_before.svg);
		content: "";
		position: absolute;
		height: 23px;
		width: 20px;
		top: 50%;
		left: 20px;
		height: 23px;
		width: 20px;
		background-size: 100%;
		background-repeat: no-repeat;
		transform: translateY(-50%);
	}
}

.slider_home{
	position: relative;
	height: 64vh;
	@media screen and (max-width: 1025px) {
		height: 44vh;
	}
	@media screen and (max-width: 560px) {
		height: 80vh;
	}

	h2{
		font-size: 64px;
		color: $red;
		text-transform: uppercase;
		margin-top: 0;
		padding-top: 100px;

		@media screen and (max-width: 1025px) {
			font-size: 35px;
			padding-top: 40px;
		}

		span{
			color: $grey;
			font-weight: 100;
			display: block;
			line-height: 0.8em;
		}
	}
	p{
		font-size: 22px;
		font-weight: 300;
		
		@media screen and (max-width: 1025px) {
			max-width: 50%;
			font-size: 20px;
		}
		@media screen and (max-width: 560px) {
			max-width: 90%;
			font-weight: 300;
		}
	}
	.cta-red{
		background-color: $red;
		padding: 14px 48px;
		color: #FFF;
		font-weight: 600;
		border-radius: 60px;
		box-shadow: 0 8px 24px rgba(255, 0, 3, 0.2);
		text-decoration: none;
		margin-top: 2em;
		display: inline-block;
	}

	.img_full-screen{
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		object-fit: cover;

		@media screen and (max-width: 1025px) {
			height: 100%;
		}

		@media screen and (max-width: 560px) {
			top: inherit;
			bottom: 0;
		    height: 46%;
		}

		img{
			height: 100%;
			object-fit: cover;
			@media screen and (max-width: 1025px) {
				height: 100%;
			}
		}
	}
}


.choice_product{
	background-color: #FCFAFA;
	ul.list_product{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 20px 0px;
		margin: 0;
		

		li{
			display: inline-block;

			a{
				text-decoration: none;
				color: $grey;
			}
			@media screen and (max-width: 1025px) {
			    width: calc(27% - 24px);
				text-align: center;

				img{
					height: 60px;
					width: 41px;
				}
				span{
					font-size: 12px;
				}
				span{
					font-size: 12px;
				}
			}
			@media screen and (max-width: 1025px) {
				font-size: 10px;
			}
			img{
				display: block;
				margin: 0 auto;
				height: 60px;
				width: 55px;
				@media screen and (max-width: 560px) {
					width: 38px;
				}
			}
			span{
				width: 100%;
				display: block;
				margin-top: 10px;
				text-align: center;
			}
		}
	}
}
.img_full-screen{
	img{
		&.home-desktop{
			display: block;
		}
		&.home-mobile{
			display: none;
		}
	}
	@media screen and (max-width: 560px) {
		img{
			&.home-desktop{
				display: none;
			}
			&.home-mobile{
				display: block;
				height: 210px;
				margin-top: 3em;

			}
		}
	}
}
.brand{
	background-color: $grey;
	position: relative;
	.content_brand{
		position: relative;
		overflow: hidden;
		h3{
			font-size: 69px;
			color: #FFF;
			margin: 0;
			padding: 70px 0 100px 0;
			@media screen and (max-width: 1025px) {
				font-size: 52px;
				
			}
			@media screen and (max-width: 560px) {
				font-size: 32px;
				padding: 40px 0 50px;
				line-height: 1;
			}
			span{
				font-weight: 300;
				display: block;
				line-height: 0.8em;
			}
		}
		ul.list_brand{
			width: 55%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0;
			@media screen and (max-width: 1025px) {
				width: 100%;
			}
			li{
				width: calc(100% / 3 - 30px);
				margin-bottom: 30px;
				opacity: .5;
				transition: .2s ease-in;
				@media screen and (max-width: 1025px) {
					width: calc(30% - 20px);
				}
				@media screen and (max-width: 560px) {
					width: calc(50% - 20px);
				}
				&:hover{
					opacity: 1;
				}
				img{
					width: 100%;
					height: 100%;
				}
				
			}
		}
	
		
	}

	
	.img_full-screen{
		position: absolute;
		right: 0;
		top: 70px;
		height: 75%;
		width: 40%;
		@media screen and (max-width: 1025px) {
		display: none;
		}
		img{
			object-fit: cover;
			width: 100%;
			height: 100%;
			border-radius: 30px 0px 0px 30px;
			overflow: hidden;
			height: 100%;
			display: none;
			&.image_default{
				display: block;
			}
		}
	}

	.info_legal{
		display: inline-block;
		margin: 20px 0 70px;
		width: 100%;
		@media screen and (max-width: 1025px) {
			padding: 0;
			font-size: 12px;
		}
		@media screen and (max-width: 560px) {
			padding: 0;
			margin: 20px 0 70px;
			width: 100%;
			flex-wrap: wrap;
			justify-content: space-between;
			/* white-space: nowrap; */
			display: flex;
		}

		li{
			display: inline-block;
			width: 33%;
			@media screen and (max-width: 1025px) {
				padding: 0;
				font-size: 12px;
			}
			@media screen and (max-width: 560px) {
				width: 50%;
    			text-align: center;
				.cta_white{
					padding: 10px 20px 10px 30px;
					position: relative;
					font-size: 11px;
					text-align: center;
					&:before{
						height: 13px;
						width: 10px;
						top: 54%;
						left: 11px;
					}
				}

				&:last-of-type{
					margin: 0 auto;
					margin-top: 20px;
					margin-bottom: 30px;
				}
			}
		}
	}
}

footer{	
	&.container{
		@media screen and (max-width: 1025px) {
			margin-top: 2em;
		}
	}
	p{
		text-align: center;
	}
	a{
		font-size: 14px;
		color: #4d555b;
		line-height: 1.4;
		text-decoration: none;
		margin-right: 20px;
		@media screen and (max-width: 640px) {
			margin-right: 20px;
			margin-bottom: 20px;
			display: block;	
		}
	}
	div{
	    display: flex;
		width: 100%;
		margin: 0 auto;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 80px auto;

		@media screen and (max-width: 1025px) {
			margin: 0px auto;
			display: inline-block;
		}
		div{
			width: 40%;
			margin: 0 auto;
			@media screen and (max-width: 1025px) {
				width: 100%;
				display: flex;
			}
		}
		img{
			max-width: 130px;
			height: 30px;
			@media screen and (max-width: 1025px) {
				margin-bottom: 50px;
			}
		}
		ul{
			list-style: none;
			margin-top: 0;
			width: 40%;
			@media screen and (max-width: 1025px) {
				width: 50%;
				padding: 0;
			}
			li{
				font-weight: 300;
				font-size: 13px;
				list-style: none;
				margin: 10px 0;
				&:nth-child(1){
					font-size: 19px;
					font-weight: 800;
					margin-bottom: 25px;

					a{
						font-weight: 800;
					}
				}

				a{
					text-decoration: none;
					color: $grey;
					opacity: .7;
					transition: .1s ease-in;
					&:hover{
						opacity: 1;
					}

					@media screen and (max-width: 1025px) {
						width: 50%;
						padding: 0;
						font-weight: 300;
					}
				}
			}
		}
	}
}


/*--------------- PAGE PRODUCT -----------------*/


.product{
	position: relative;
	min-height: 40vh;
	padding-bottom: 8em;
	
	h1{
		font-size: 69px;
		color: $red;
		font-weight: 600;
		margin-top: 0;
		padding-top: 100px;
		@media screen and (max-width: 1025px) {
			font-size: 49px;
			padding-top: 20px;
		}
	}
	p{
		max-width: 45%;
		font-size: 22px;
		font-weight: 300;
		@media screen and (max-width: 1025px) {
			max-width: 100%;
			font-size: 18px;
		}
		@media screen and (max-width: 560px) {
			font-weight: 300;
		}
	}
	.img_full-screen{
		position: absolute;
		right: 0;
		top: 0px;
		height: 90%;
		width: 50%;
		@media screen and (max-width: 1025px) {
			position: relative;
			width: 100%;
			margin-top: 40px;
		}
		img{
			object-fit: cover;
			width: 100%;
			height: 100%;
			border-radius: 0px 0px 0px 30px;
			overflow: hidden;
			height: 100%;
		}
	}
}

.product-list{
	background-color: #FAFAFA;
	padding: 70px 0;
	@media screen and (max-width: 1025px) {
		padding: 20px;
	}
	.filters_product{
		ul{
			display: inline-block;
			padding: 0;
			li{
				display: inline-block;
				padding: 12px 25px;
				border: 1px solid #FFD2D4;
				border-radius: 50px;
				color: $red;
				margin-right: 20px;
				transition: .2s ease-in;
				margin-bottom: 40px;
				cursor: pointer;
				@media screen and (max-width: 1025px) {
					font-size: 10px;
					padding: 8px 20px;
					margin-right: 5px;
					margin-bottom: 20px;
				}

				&:hover{
					background-color: #FFD2D4;
				}

				&.active-filter{
					background-color: #FFD2D4;
				}
			}
		}
	}

	.list_product{
		ul{
			display: inline-block;
			padding: 0;

			@media screen and (max-width: 1025px) {
				justify-content: space-between;
			
			}
		}
		li{
			// height: 500px;
			background-color: #fff;
			box-shadow: $box-shadow;
			width: calc(32% - 30px);
			border-radius: 30px;
			list-style: none;
			margin-bottom: 80px;
			margin-right: 30px;
			display: inline-block;

			@media screen and (max-width: 1025px) {
				width: 100%;
				height: 100%;
				width: calc(100% / 2 - 30px);
				margin-right: 0px;
				overflow: hidden;
			
			}
			@media screen and (max-width: 580px) {
				width: 100%;
			}

			.img_product{
				// height: 60%;
				@media screen and (max-width: 1025px) {
					height: 40%;
				}
				img{
					object-fit: contain;
					width: 100%;
					height: 100%;
					border-radius: 0px 0px 0px 30px;
					overflow: hidden;
					height: 100%;
					max-height: 210px;

				}
			}
			div:last-child{
				background-color: $grey;
				border-radius: 30px;

				p{
					color: #fff;
					text-align: center;
					font-size: 16px;
					padding: 30px 45px;
					margin-top: 0;
					min-height: 90px;
					@media screen and (max-width: 1025px) {
						padding: 30px 15px;
						font-size: 14px;
					}
					span{
						font-weight: 300;
						display: block;
						margin-top: 20px;
						@media screen and (max-width: 560px) {
							font-weight: 300;
						}
					}
				}

				p > a,
				p > a:is(:hover, :active, :visited, :focus) {
					color: white;
					text-decoration: none;
				}

				.cta-white-product{
					margin-left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
}




.swiper-container {
	width: 100%;
	//padding-right: 2em;
	height: 300px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 2em;
  }

  .swiper-slide {
	background-size: cover;
	background-position: center;
	display: inline-block;
	@media screen and (max-width: 1025px) {
		background-position: 20%;
	}
	@media screen and (max-width: 590px) {
		background-position: 50%;
	}
	
  }

  .gallery-top {
	height: auto;
	width: 60%;
	overflow: hidden;
	
	.swiper-wrapper{
		.swiper-slide{
			background-size: contain;
			background-repeat: no-repeat;
			@media screen and (max-width: 590px) {
				height: 150px;
			}
		}
		img.swiper-slide {
			object-fit: contain;
			object-position: center;;
		}
	}
  }

  .gallery-thumbs {
	height: 20%;
	box-sizing: border-box;
	padding: 10px 0;
	height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 0;
    width: 20%;
	/* display: inline-block;*/

	.swiper-wrapper{
		display: inline-block;
	}
	@media screen and (max-width: 1025px) {
		width: 10%;
		margin: 0;
	}
	@media screen and (max-width: 590px) {
		width: 20%;
		margin: 0;
	}
	
	.swiper-slide{
		display: inline-block;
		width: 100% !important;
		height: 200px !important;
		background-size: contain;
		background-repeat: no-repeat;
		@media screen and (max-width: 1025px) {
			height: 90px!important;
		}
		@media screen and (max-width: 590px) {
			height: 70px!important;
		}
	}
	img.swiper-slide {
		object-fit: contain;
		object-position: center;;
	}
  }

  .gallery-thumbs .swiper-slide {
	width: 25%;
	height: 100%;
	opacity: 0.8;
  }

  .gallery-thumbs .swiper-slide-thumb-active {
	opacity: 1;
  }

.product-content{
	display: flex;
    flex-wrap: wrap;
	justify-content: space-between;
	
	.swiper_product{
		height: 80vh;
		width: 50%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@media screen and (max-width: 1025px) {
			width: 100%;
			height: 40vh;
		}
			@media screen and (max-width: 590px) {
				max-height: 260px;
				min-height: 250px;
				
			}
		
		.swipper-button{
			position: absolute;
			bottom: 80px;
			right: 100px;
			@media screen and (max-width: 1025px) {
				bottom: 40px;
			}
			.swiper-button-white{
				background-color: #FFF;
				box-shadow: $box-shadow;
				width: 60px;
				height: 60px;
				border-radius: 100px;
				background-image: url('/dist/img/arrow_slider.svg');
				background-size: 20%;
				background-repeat: no-repeat;
				background-position: 50%;
				@media screen and (max-width: 1025px) {
					width: 30px;
					height: 30px;
				}
				&:after{
					display: none;
				}
			}
			.swiper-button-prev{
				right: 30px;
				left: auto;
				transform: rotate(180deg);
			}
			.swiper-button-next{
				right: -60px;
				left: auto;
			}
		}
		
	  }
	
	  .product_infos{
		background-color: #E8E9EA;
		width: 50%;
		height: 80vh;
		position: relative;
		@media screen and (max-width: 1025px) {
			width: 100%;
			height: auto;
			margin-bottom: 2em;
			   
			   &:before{
				background-color: #e8e9ea;
				width: 100vw;
				position: absolute;
				content: "";
				left: -62px;
				top: 0;
				height: 100%;
				z-index: -1;
				overflow: hidden;
			}
		}
		@media screen and (max-width: 590px) {
			margin-bottom: 0em;
			&:before{
				background-color: #e8e9ea;
				width: 100vw;
				position: absolute;
				content: "";
				left: -5vw;
				top: 0;
				height: 100%;
				z-index: -1;
				overflow: hidden;
			}
		}
		&:after{
			content: "";
			height: 100%;
			width: 100%;
			background-color: #E8E9EA;
			right: -100%;
			top: 0;
			position: absolute;
			@media screen and (max-width: 1025px) {
				display: none;
			}
		}
		h3{
			font-size: 23px;
			@media screen and (max-width: 1025px) {
				font-size: 18px;
			}
		}

		.content-product_infos{
			padding: 2em 3em 2em 6em;
			@media screen and (max-width: 1025px) {
				bottom: 40px;
				padding: 2em;
			}

			
			h4{
				margin-top: 2em;
			}
			span{
				font-weight: 300;
				font-size: 12px;
				@media screen and (max-width: 560px) {
					font-weight: 300;
				}
				&.price{
					font-size: 20px;
					font-weight: 800;
				}
			}
		}
		.commerce_connector{
			width: 100%;
			margin-top: 2em;
			border-radius: 30px;

			.shop_online{
				background-color: $grey;
				padding: 14px 48px;
				color: #FFF;
				font-weight: 600;
				border-radius: 60px;
				box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
				text-decoration: none;
				margin-top: 2em;
				display: inline-block;
				transform: translateY(0px);
				transition: .2s ease-in-out;
				margin-bottom: 50px;
				border:none;
				cursor: pointer;
				
				&:hover{
					transform: translateY(-3px);
					box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
				}
			}

			.shop_online-close{
				font-size: 1.2em;
				margin-top: 100px;
				span{
					display: block;
					font-weight: 300;
					font-size: 14px;
				}
			}
			.list-resellers{
				display: none;
				&.list-resellers-open{
					display: block;
				}
			}
			img{
			    max-width: 153px;
				background-color: #fff;
				padding: 1.5em;
				border-radius: 20px;
				max-height: 40px;
				margin: 20px 20px 20px 0;
				@media screen and (max-width: 1025px) {
					margin: 0px 20px 20px 0;
				}

			}
		}
	  }
}

.shop_online{
	background-color: $grey;
	padding: 14px 48px;
	color: #FFF;
	font-weight: 600;
	border-radius: 60px;
	box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
	text-decoration: none;
	margin-top: 2em;
	display: inline-block;
	transform: translateY(0px);
	transition: .2s ease-in-out;
	margin-bottom: 50px;
	border:none;
	cursor: pointer;
	
	&:hover{
		transform: translateY(-3px);
		box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
	}
}
.breacrumbs{
	position: absolute;
	bottom: 0;
	list-style: none;
	padding-left: 0;

	@media screen and (max-width: 1025px) {
		bottom: inherit;
		top: 0;
	}
	
	li{
		display: inline-block;
		text-align: left;
	}
	a{
		color: $grey;
		font-size: 12px;
		position: relative;
		padding-right: 30px;
		text-decoration: none;
		&:after{
			background: url(/dist/img/cta_before.svg);
			content: "";
			position: absolute;
			height: 13px;
			width: 10px;
			top: 50%;
			right: 30%;
			background-size: 100%;
			background-repeat: no-repeat;
			transform: translate(50% , -50%) rotate(91deg);
		}
		&.active{
			color: $red;

			&::after{
				display: none;
			}
		}
	}
}

.contact{
	display: none;
}

.garantie-active{
	.garantie , .garantie_content{
		display: block;
	}
	.map_service , .infos-map , .reparation , .contact{
		display: none;
	}
}
.reparation-active{
	.garantie , .garantie_content{
		display: none;
	}
	.map_service , .infos-map , .reparation , .contact{
		display: block;
	}
}

.contact-active{
	.contact{
		display: block;
	}
	.map_service , .infos-map , .reparation , .garantie_content{
		display: none;
	}
}




/* MODAL GPDR */

.modal-gdpr{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 25000;
	background: rgba(0,0,0,.8);
	display: none;

	h4{
		font-size: 2rem;
		margin-top: .5rem;
		position: relative;
		color: #ed1d23;
    	padding-bottom: 1.5rem;


	}
	.text-intro{
		font-size: 1rem;
    	margin-bottom: 3rem;
	}
}

.modal-dialog{
	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background: #fff;
	max-height: 90vh;
	width: 75%;
	max-width: 1200px;
	padding: 2em;
	overflow-y: auto;

	 @media screen and (max-width: 1200px) {
		width: 90%;
	}

	.modal-content .modal-header{
		button.close{
		    border: none;
		    font-size: 48px;
		    color: #ed1d23;
		    padding-right: 0;
		    position: absolute;
		    right: 3%;
		    top: 4%;
		    cursor: pointer;
		    z-index: 30;
		    background-color: transparent;
		    transition: .3s ease;

		    &:hover{
		    	transform: scale(0.8);
			}
			.sr-only {
				border: 0;
				clip: rect(0,0,0,0);
				height: 1px;
				margin: -1px;
				overflow: hidden;
				padding: 0;
				position: absolute;
				width: 1px;
			}
		}
	}
	button{
		border: none;
	}
	.modal-footer button.btn-save{
		float: right;
		background: #ed1d23;
		background-size: 200% 100%;
		color: #FFF;
		border: none;
		margin-top: 4rem;
		padding: 15px 30px;
		font-weight: 600;
    	font-family: 'Roboto', sans-serif;
    	font-style: italic;
    	cursor: pointer;
    	background-position: 0 0;
    	transition: .3s ease;

    	&:hover{
    		background-position: 100% 0;
		}
		
	}
}
.category-container{
	margin-left: -15px;
}
.category{
	width: 22%;
	float: left;
	padding-left: 15px;
	padding-right: 15px;
	@media screen and (max-width: 1080px) {
		width: 20%;
	}
	&.active{

		.category-title .select-item-btn{
			button.btn-yes{
				background: #ed1d23;
				color: #FFF;
				border: none;
			}
			button.btn-no{
				background: transparent;
				color: #333;
				border: 1px solid #ccc;
			}
		}
	}

	@media screen and (max-width: 765px) {
		width: 100%;
	}

	.select-item-btn{
		
		cursor: pointer;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 100px;
		display: flex;
		flex-wrap: wrap;
		float: right;
		width: 63px;
		margin-left: 20px;
		
		.btn{
			display: inline-block;
			margin-bottom: 0;
			font-weight: 400;
			text-align: center;
			vertical-align: middle;
			cursor: pointer;
			background-image: none;
			white-space: nowrap;
			padding: 3px 6px;
			line-height: 1.428571429;
			user-select: none;
			border: 1px solid #ccc;
			width: 50%;
			margin-left: -1px;
			font-size: 10px;
			height: 25px;
		}
		.btn-yes{

			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			margin-left: -5px;
			border-right: 1px solid transparent;
		}
		.btn-no{
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			border-left: 1px solid transparent;
			background: #ed1d23;
			color: #FFF;
			border: none;
		}
	}

	.category-title{
		margin-bottom: 2rem;
		font-size: 13px;
		p{
			font-weight: 700;
			margin-bottom: 1.5rem;
			display: inline;
		}
	}

	.category-description{
		font-size: 11px;
		text-align: justify;

		@media screen and (max-width: 765px) {
			margin-bottom: 1rem;
		}   
	}
}

.cookie-bar{
	position: fixed;
	width: 100%;
	padding: 105px 0;
	left: 0;
	z-index: 2500;
	background: rgba(0,0,0,.8);
	bottom: 0;

	p{
		color: #FFF;
		font-size: 16px;
		margin: 0 auto;
		text-align: center;
		margin: 10px 50px;
		@media screen and (max-width: 415px) {
			font-size: 12px;
			margin: 10px 15px;
		}
	}
	div{
		text-align: center;
		display: block;
		margin: 0 auto;
		a{
			color: #FFF;
			padding-bottom: 5px;
			font-weight: 700;
			margin-top: 10px;
			font-size: 12px;
			display: inline-block;
			
			&.accept_cookie {
				cursor: pointer;
			    border: none;
			    text-decoration: none;
			    color: #fff;
			    background: #ed1d23;
			    background-size: 200% 100%;
			    padding: 10px 30px;
			    font-style: italic;
			    font-weight: 600;
			    font-size: 16px;
			    display: inline-block;
			    transition: .3s ease;
			    background-position: 0 0;

		    	&:hover{
		    		background-position: 100% 0;
		    	}
			}

			&:first-child{
				margin-right: 50px;
				@media screen and (max-width: 411px) {
					margin-right: 0px;
				} 
			}
		}
	}
}
.show{
	display: block;
}
.noshow{
	display: none;
}
.clearfix::after {
	content: "";
	clear: both;
	display: table;
}



















/*
	CSS for the inline version of Commerce Connector
*/
.cciw {
	&.cciw-widget {
		padding-top: 25px;
		padding-bottom: 0;
		background-color: transparent;
	}
	
	:is(.cciw-title, .cciw-notices) span._t {
		font-size: 16px;
		font-weight: 400;
		color: $grey;
	}
	.cciw-notices::before {
		margin-bottom: 25px;
	}

	.online-shop .shop-logo {
		background-color: #fff;
		border-radius: 8px;
	}
	.online-shop .shop-availability span {
		font-weight: 400;
	}
}