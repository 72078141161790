.services {
	
	
	section {

		padding: 2em 0;
	
		ul{
			padding: 0;
			margin: 0 0 2em 0;
			li {
				list-style: none;
			}
			
			&.classic {
				padding: 1em;
				li {
					list-style: initial;
				}
			}
		}
		
		h2{
			font-size: 64px;
			color: $red;
			margin-top: 0;
			padding-top: 50px;
			@media screen and (max-width: 790px) {
				font-size: 39px;
			}
		} 
		
		h3 {
			font-size: 22px;
			font-weight: 300;
		}
		
		h4 {
			font-size: 20px;
		}
		
		h5{
			color: $red;
			font-weight: bold;
			font-size: 1.2em;
			margin: 0;
			padding: 0;
		}
		
		
		li {
			font-size: 1.1em;
		}
		
		p {
			font-size: 1.4em;
		}
		
		a {
			color: $red;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		
		&.color-grey{
			
			background-color: $grey;
			
			h2, h3, h4 , p , h3 , li{
				color: #fff;
			}
		
			h5{
				color: #BBB;
			}
			
			a{
				color: #fff;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			
		}
		
		&.center {
			text-align: center;
		}

	
	}
		
}

