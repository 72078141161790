nav {

	&.menu-responsive{
		&:before{
		    // height: 300vh;
			// content: "";
			// position: absolute;
			// left: 0;
			// background-color: #000000d1;
			// width: 100vw;
			// z-index: 10;
			// top: 70vh;
		}
		.menu{
			display: block;
		}
		.sub_menu-content{
			@media screen and (max-width: 1025px) {
				width: 95%;
				left: 0;
				text-align: center;
			}
		}
			a{
				@media screen and (max-width: 1025px) {
					color: $red;
					font-size: 19px;
					text-transform: uppercase;
					text-align: left;
					opacity: 1;
				}
			}
			.sub_menu{
				
				@media screen and (max-width: 1025px) {
					position: relative;
					padding: 0;
					z-index: 20;
					top: inherit;
					margin-top: 20px;
					left: 0;
					border: none;
					box-shadow: none;
					
					
					p{
						display: none;
					}
				}
				
				ul{
					@media screen and (max-width: 1025px) {
						columns: 1;
						text-align: center;
						width: 100%;
					}
					li{
						
						@media screen and (max-width: 1025px) {
							columns: 1;
							text-align: center;
						}
					}
				}
				.img_full-screen{
					
					@media screen and (max-width: 1025px) {
						display: none;
					}
				}
			}
	}
	.menu{
		li {
			&:hover{
				.sub_menu{
					display: block;
				}
			}
			a{
				text-transform: uppercase;
				color: $grey;
				text-decoration: none;
				opacity: .6;
				transition: .2s ease-in;
				@media screen and (max-width: 1025px) {
					color: $red;
					font-size: 19px;
					text-transform: uppercase;
					text-align: left;
					opacity: 1;
					display: block;
				}

				&:hover{
					opacity: 1;
				}
			}
			.sub_menu{
				background-color: #fff;
				box-shadow: $box-shadow;
				opacity: 1;
				pointer-events: auto;
				display: block;
				text-align: center;
				position: absolute;
				background-color: #FFF;
				right: 0;
				width: 100%;
				border-top: 1px solid #eee;
				top: 100%;
				padding-bottom: 2rem;
				min-height: 300px;
				max-height: 300px;
				-webkit-transition: .8s;
				transition: .8s;
				padding-top: 2em;
				top: 100px;
				z-index: 10;
				display: none;
				@media screen and (max-width: 1025px) {
					position: relative;
					padding: 0;
					z-index: 20;
					top: inherit;
					margin-top: 20px;
					left: 0;
					border: none;
					box-shadow: none;
					display: block;
					min-height: 260px;
					max-height: 250px;
					
					p{
						display: none;
					}
				}
				

				p{
					color: $red;
					font-size: 19px;
					text-transform: uppercase;
					text-align: left;
				}
				ul{
					display: block;
					width: 40%;
					padding: 0;
					columns: 2;
					@media screen and (max-width: 1025px) {
						columns: 1;
						text-align: center;
						width: 100%;
					}
					li{
						display: block;
						text-align: left;
						font-weight: 100;
						transition: .2s ease-in;
						margin: 0 10px 10px;
						padding: 0;
						@media screen and (max-width: 1025px) {
							columns: 1;
							text-align: center;
							position: relative;
						    text-align: left;
    						padding-left: 60px;
							&:before{
								height: 30px;
								width: 30px;
								position: absolute;
								left: 0;
								top: 0;
								content: "";
								background: url(/dist/img/icon01.svg);
								background-repeat: no-repeat ;
							}
							&:nth-child(1){
							&:before{
								background: url(/dist/img/icon01.svg);
								background-repeat: no-repeat ;
								background-size: contain;
								}
							}
							&:nth-child(2){
								&:before{
								background: url(/dist/img/icon02.svg);
								background-repeat: no-repeat ;
								background-size: contain;
								}
							}
							&:nth-child(3){
								&:before{
								background: url(/dist/img/icon03.svg);
								background-repeat: no-repeat ;
								background-size: contain;
								}
							}
							&:nth-child(4){
								&:before{
								background: url(/dist/img/icon04.svg);
								background-repeat: no-repeat ;
								background-size: contain;
								}
							}
							&:nth-child(5){
								&:before{
								background: url(/dist/img/icon05.svg);
								background-repeat: no-repeat ;
								background-size: contain;
								}
							}
							&:nth-child(6){
								&:before{
								background: url(/dist/img/icon06.svg);
								background-repeat: no-repeat ;
								background-size: contain;
								}
							}
							&:nth-child(7){
								&:before{
								background: url(/dist/img/icon07.svg);
								background-repeat: no-repeat ;
								background-size: contain;
								}
							}
							
						}
						@media screen and (max-width: 560px) {
							font-weight: 300;
						}
						a{
							text-transform: inherit;
							padding: 10px;
						}
						&:hover{
							font-weight: 500;
						}
					}
				}
				.img_full-screen{
					position: absolute;
					right: 0;
					height: 100%;
					top: 0;
					width: 40%;
					@media screen and (max-width: 1025px) {
						display: none;
					}
					img{
						object-fit: cover;
						width: 100%;
						height: 100%;
						overflow: hidden;
						height: 100%;
					}
				}
			}
		}
	}	
}

.sub_menu-content{
	width: 50%;
	position: absolute;
	left: 30%;
	@media screen and (max-width: 1025px) {
		width: 95%;
		left: 0;
		text-align: center;
	}
}